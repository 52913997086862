import 'reflect-metadata';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// import '@additiv-hydrogen/ui.styles';
import '@additiv-hydrogen/tailwind-styles/styles.css';
import { queryClientConfig } from './configs/query-client.config';

import './configs/inversify.config';
import './configs/ui-library.config';
import './configs/localization.config';
import * as serviceWorker from './sw';
import App from './App';
import './index.css';

serviceWorker.register();
const queryClient = new QueryClient(queryClientConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <QueryClientProvider client={queryClient}>
        <App />
        <div className="print:hidden block">
            <ReactQueryDevtools initialIsOpen={false} />
        </div>
    </QueryClientProvider>,
);
