import React, { useEffect } from 'react';
import { useSearchParams } from '@additiv-hydrogen/core.router';
import { parseJwt } from '@additiv-hydrogen/core.utils.base';
import { useMutation } from 'react-query';
import { customApi } from '@additiv-hydrogen/core.rest.transport';

export const SecureNew = ({
    onUserLoaded = () => {},
    onLogout = () => {},
    children,
}: any) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') || '';

    const { mutateAsync: getPreferences } = useMutation(
        () => customApi().get('/v1.0/widget/instruments/preferences'),
        { onError: () => onLogout() },
    );

    useEffect(() => {
        const userInfo = parseJwt(token);

        if (userInfo) {
            if (typeof onUserLoaded === 'function') {
                onUserLoaded({
                    profile: { mid: userInfo.mid },
                    access_token: token,
                });
                // make an api call to check if token is actually valid
                getPreferences();
            }
        } else if (typeof onLogout === 'function') {
            onLogout();
        }
    }, [token]);

    return <>{children}</>;
};
