import React, { memo, Suspense, useMemo } from 'react';
import Router, {
    withBrowserRouter, setRouterConfigs, useNavigate, useSearchParams,
} from '@additiv-hydrogen/core.router';
import { useMode } from '@additiv-hydrogen/ui.theme';
import AuthenticationProvider, {
    Secure,
} from '@additiv-hydrogen/auth.identity-server';
import { dispatchMemberId } from '@additiv-hydrogen/data.wealth.app.use-member-id';
import {
    setAccessToken,
    setRestConfig,
    getConfigData,
} from '@additiv-hydrogen/core.rest.transport';
import { InitLanguage } from '@additiv-hydrogen/core.i18n';
import {
    setGQLAuthToken, setGQLConfigUrl, setGqlConfig,
} from '@additiv-hydrogen/core.gql.transport';
import { initAndStart, removeTokenAndFinish } from '@additiv-hydrogen/core.signalr';
import { clearFormStore } from '@additiv-hydrogen/ui.configs';
import { routingConfig } from './configs/routing.config';
import { notificationConfig } from './configs/notifications.config';
import { serviceConfig } from './configs/service.config';
import { config } from './configs/identity-server.config';
import { LoaderBase } from './configs/loader-base';
import { SecureNew } from './secure-new';

setRouterConfigs(routingConfig);
const setSWAccessToken = (accessToken) => {
    window?.navigator?.serviceWorker?.controller?.postMessage({
        type: 'ACCESS_TOKEN',
        payload: accessToken,
    });
};

const withMode = (Component) => (props) => {
    useMode();

    return <Component {...props} />;
};

const redirectUrl = '/dashboard';

function App() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') || '';
    const [isAuth, setAuth] = React.useState(false);

    setRestConfig({
        ...serviceConfig,
        headers: getConfigData().headers,
    });
    setGQLConfigUrl(serviceConfig?.GATEWAY_API_URL || '');
    setGqlConfig({
        urlSuffix: serviceConfig?.GQL_URL_SUFFIX,
    });
    const onLogin = (oidcUser) => {
        dispatchMemberId(+oidcUser.profile.mid);
        setAccessToken(oidcUser.access_token);
        setGQLAuthToken(oidcUser.access_token);
        setSWAccessToken(oidcUser.access_token);
        InitLanguage('AdvisorHub');
        initAndStart({
            ...notificationConfig,
            accessToken: oidcUser.access_token,
            identity: {
                authority: config.authority,
                client_id: config.client_id,
                tenant_uid: config.tenant_uid,
            },
        });

        setAuth(true);
    };

    const onLogout = () => {
        removeTokenAndFinish();
        clearFormStore();
    };

    const Component = useMemo(() => {
        if (token) {
            return {
                Comp: SecureNew,
                props: {
                    onUserLoaded: onLogin,
                    onLogout: () => {
                        onLogout();
                        window.location.href = window.location.pathname;
                    },
                },
            };
        }

        return !isAuth ? { Comp: Secure, props: {} } : { Comp: 'div', props: {} };
    }, [token, isAuth]);

    return (
        <AuthenticationProvider
            loadingComponent={(
                <LoaderBase />
            )}
            configuration={config}
            onUserLoaded={onLogin}
            onLogout={onLogout}
            onRedirect={() => navigate(redirectUrl)}
        >
            <Suspense fallback={(<LoaderBase />)}>
                <Component.Comp {...Component.props}>
                    <>
                        {(isAuth && !!getConfigData().headers?.Authorization)
                            ? <Router />
                            : <LoaderBase />}
                    </>
                </Component.Comp>
            </Suspense>

        </AuthenticationProvider>
    );
}

export default withBrowserRouter(withMode(memo(App)));
