import { setLocalizeConfig } from '@additiv-hydrogen/ui.configs';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import frCH from 'date-fns/locale/fr-CH';
import itCH from 'date-fns/locale/it-CH';

setLocalizeConfig({
    availableLanguages: [
        {
            id: 'en-GB',
            label: 'common.english',
            subLabel: 'common.en-gb',
            value: 'en-GB',
            locale: enGB,
        },
        {
            id: 'en-US',
            label: 'common.english',
            subLabel: 'common.en-us',
            value: 'en-US',
            locale: enUS,
        },
        {
            id: 'de',
            label: 'common.german',
            subLabel: 'common.de-de',
            value: 'de',
            locale: de,
        },
        {
            id: 'de-CH',
            label: 'common.german',
            subLabel: 'common.de-ch',
            value: 'de-CH',
            locale: de,
        },
        {
            id: 'fr-CH',
            label: 'common.french',
            subLabel: 'common.fr-ch',
            value: 'fr-CH',
            locale: frCH,
        },
        {
            id: 'it-CH',
            label: 'common.italian',
            subLabel: 'common.it-ch',
            value: 'it-CH',
            locale: itCH,
        },
    ],
});
