import Base from '@additiv-hydrogen/ui.layouts.base';
import Loader, { LoaderSizes } from '@additiv-hydrogen/ui.components.loader';
import React from 'react';

export const LoaderBase = () => (
    <Base>
        <div style={{ margin: 'auto' }}>
            <Loader size={LoaderSizes.extraLarge} />
        </div>
    </Base>
);
